<template lang="pug">
b-container(fluid)
  b-row.mb-3
    b-col
      h3 Dispositivos
      p.text-secondary Los dispositivos aparecerán aquí automáticamente una vez que tengan instalada la actualización compatible
  b-table(
    :items="devices",
    :fields="fields",
    responsive,
    hover,
    no-border-collapse,
    @row-clicked="openRow",
    show-empty
  )
    template(#empty)
      .text-center No hay datos para mostrar
    template(#cell(name)="{ item }")
      span(:class="item.name ? null : 'text-secondary'") {{ item.name || 'Sin nombre' }}
    template(#cell(cliente_id)="{ item }")
      span(:class="item.cliente_id ? null : 'text-secondary'") {{ getClienteName(item.cliente_id) || 'Sin asignar' }}
    template(#cell(shouldUpdate)="{item}")
      clock-icon.text-info(v-if="item.shouldUpdate")
      check-circle-icon.text-success(v-else)
  .d-flex.align-items-center.mb-3
    b-pagination.mb-0(
      v-model="paginationPage",
      :total-rows="paginationTotal",
      :per-page="paginationLimit",
      @input="getDevices"
    )
    b-spinner.ml-3(variant="primary", small, v-if="loadingData")
</template>

<script>
import { createNamespacedHelpers, mapActions } from "vuex";
const deviceController = createNamespacedHelpers("device");
const clienteController = createNamespacedHelpers("cliente");

export default {
  data() {
    return {
      newDeviceName: "",
      createDeviceModal: false,
      fields: [
        {
          key: "device_id",
          label: 'ID'
        },
        {
          key: "name",
          label: "Nombre dispositivo"
        },
        {
          key: 'cliente_id',
          label: 'Cliente asignado'
        },
        {
          key: 'shouldUpdate',
          label: 'Actualizado'
        }
      ],
      timeout: null,
      shouldRefresh: true
    };
  },
  computed: {
    ...deviceController.mapState({
      devices: (state) => state.devices,
    }),
    ...clienteController.mapState({
      clientes: (state) => state.clientes,
    }),
  },
  methods: {
    ...mapActions(["device/all", 'cliente/all']),
    getDevices() {
      this.loadingData = true;
      this["device/all"]({
        params: {
          page: this.paginationPage,
          limit: this.paginationLimit,
        },
        callback: (res) => {
          this.loadingData = false;
          clearTimeout(this.timeout);

          if (res.success) {
            this.paginationTotal = res.total;
            this.paginationHasNextPage = res.hasNextPage;
          } else {
            this.$bvToast.toast(
              `Ocurrió un error obteniendo los dispostivos. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }

          if (this.shouldRefresh) {
            setTimeout(() => {
              this.getDevices()
            }, 3000);
          }
        },
      });
    },
    getClientes() {
      this["cliente/all"]({
        params: {
          page: 1,
          limit: 10000,
        },
        callback: (res) => {
          if (res.success) {
            
          } else {
            this.$bvToast.toast(
              `Ocurrió un error obteniendo los clientes. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    getClienteName (cliente_id) {
      if (cliente_id) {
        let cliente = this.clientes.find(c => c.cliente_id == cliente_id)
        if (cliente) return cliente.name
      }

      return null
    },
    openRow(item) {
      this.$router.push({
        name: "Device",
        params: { device_id: item.device_id },
      });
    },
  },
  mounted() {
    this.getDevices();
    this.getClientes()
  },
  beforeDestroy () {
    this.shouldRefresh = false
    clearTimeout(this.timeout)
  }
};
</script>

<style>
</style>